// ==============================
// Logo
// =============================

.logo-wrapper {
  clear: both;

  .logo {
    display: block;
    // font-size: $logo-font-size;
    font-family: $logo-font-family;
    background: url('../img/ry-logo.png') no-repeat -25px 0;
    height: 150px;
    padding-left: 1px;
    background-size: 400px;

    .logo-title {
      visibility: hidden;
    }
  }
}

@include max-screen() {
  .logo-wrapper {
    display: none;
  }
}

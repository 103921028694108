// ==============================
// Iconfont
// ==============================

@font-face {
  font-family: 'iconfont';

  src: url('../fonts/iconfont/iconfont.eot');
  src: url('../fonts/iconfont/iconfont.eot#iefix') format('embedded-opentype'), // not '?#iefix', because webpack will add '?hash=[hash]'
  url('../fonts/iconfont/iconfont.woff') format('woff'),
  url('../fonts/iconfont/iconfont.ttf') format('truetype'),
  url('../fonts/iconfont/iconfont.svg#iconfont') format('svg');
}

%base-iconfont {
  font-family: "iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-text-stroke-width: 0.2px;
  cursor: pointer;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  font-feature-settings: "liga";
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconfont {
  @extend %base-iconfont;
}

/* Generic Icon */
.icon-heart:before {
  content: "\e608";
}
.icon-right:before {
  content: "\e60a";
}
.icon-left:before {
  content: "\e60b";
}
.icon-up:before {
  content: "\e60c";
}
.icon-close:before {
  content: "\e60f";
}
.icon-link:before {
  content: "\e909";
}


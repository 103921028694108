// ==============================
// Post
// ==============================


.posts {
  margin-bottom: $post-list-margin-bottom;
  border-bottom: $post-border;
  
  @include min-screen() {
    columns: 2;
    column-gap: 80px;
  }
}

.post {
  padding: $post-padding;

  &.post-cover {
    display: block;
    width: 100%;
    break-inside: avoid;

    .post-cover-img {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1); 
      display: block; 
      margin-bottom: 20px;
      border-radius: 6px; 
      height:180px;
    }

    ul {
      padding: 0 0 0 32px;
      margin: 0;
      list-style: none;
      li::before {
        content: "\00BB";
        color: $theme-color;
        display: inline-block;
        width: 1em; 
        margin-left: -1em;
      }
    }
  }
  @import '_post/header';
  @import '_post/toc';
  @import '_post/content';
  @import '_post/footer';
}

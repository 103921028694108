// ==============================
// Menu
// =============================

.site-navbar {
  clear: both;
  float: left;
  width: 100%;
  margin: 10px 0 10px 0;

  .menu {
    display: inline-block;
    position: relative;
    padding-left: 0;
    padding-right: 25px;
    font-family: $button-font-family;

    .menu-item {
      display: inline-block;
      & + .menu-item {
        margin-left: $menu-item-margin-left;
      }

    }

    .menu-item-link {
      font-size: $menu-link-font-size;
      display:inline-block;
      padding: 5px 9px;
      font-style: italic;
      border-radius:5px;
      color: #605050;
      
      &.current {
        background-color: $theme-color;
        color: $white;
      }

      &:not(.current):hover {
        background-color: #f0f0f0;
      }
    }

  }
}

@include max-screen() {
  .site-navbar {
    display: none;
  }
}

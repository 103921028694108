// ==============================
// Post content
// ==============================

.post-content {
  word-wrap: break-word;

  @for $i from 1 through 6 {
    h#{$i} {
      font-weight: 400;
      font-family: $global-serif-font-family;

      .anchor {
        float: left;
        line-height: 1;
        margin-left: -20px;
        padding-right: 4px;

        &:hover {
          border-bottom: initial;
        }

        .icon-link {
          visibility: hidden;
          font-size: 16px;

          &:before {
            vertical-align: middle;
          }
        }
      }

      &:hover {
        .icon-link {
          visibility: visible;
        }
      }
    }
  }

  a {
    color: $theme-color;
  }

  blockquote {
    margin: 2em 0;
    padding: 10px 20px;
    position: relative;
    color: rgba(#34495e, 0.8);
    background-color: $content-blockquote-backgroud;
    border-left: $content-blockquote-border-left;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.125);

    p {
      margin: 0;
    }
  }

  img {
    display: inline-block;
    max-width: 100%;
  }

  .table-wrapper {
    overflow-x: auto;

    > table {
      max-width: 100%;
      margin: 10px 0;
      border-spacing: 0;
      box-shadow: 2px 2px 3px rgba(0,0,0,.125);

      thead {
        background: $deputy-color;
      }

      th, td {
        padding: 5px 15px;
        border: 1px double $content-table-border-color;
      }

      tr:hover {
        background-color: $deputy-color;
      }
    }
  }

  @import 'code';
  @import 'admonition';

  .post-summary {
    margin-bottom: 1em;
  }

  .read-more {
    .read-more-link {
      color: $theme-color;
      display: inline-block;
      border-radius: 5px;
      padding: 4px 15px;
      border: 1px solid $theme-color;
      font-family: $button-font-family;
      font-size:15px;
      font-style: italic;

      &:active {
        color: $white;
        background-color: $theme-color;
      }

      &::after {
        content: " >>";
      }
    }
  }

  kbd {
    display: inline-block;
    padding: 0.25em;
    background-color: #fafafa;
    border: 1px solid #dbdbdb;
    border-bottom-color: #b5b5b5;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #b5b5b5;
    font-size: 0.8em;
    line-height: 1.25;
    font-family: "SFMono-Regular","Liberation Mono","Roboto Mono",Menlo,Monaco,Consolas,"Courier New",Courier,monospace;
    color: #4a4a4a;
  }

  dl dt::after {
    content: ':';
  }

  figure {
    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }

    &.left {
      text-align: left;
    }

    figcaption h4 {
      color: #b5b5b5;
      font-size: 0.9rem;
    }
  }

  hr {
    margin: 1rem 0;
    position: relative;
    border-top: 2px dashed $theme-color;
    border-bottom: none;
  }

  .footnote-ref {
    > a {
      font-weight: bold;
      margin-left: 3px;

      &:before {
        content: "[";
      }

      &:after {
        content: "]";
      }
    }
  }

  .task-list {
    list-style: none;
    padding-left: 1.5rem;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

  .align-left {
    text-align: left;
  }

  .MJXc-display {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

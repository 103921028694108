input {
    &.wide {
        width: 100%;
    }

    &.narrow {
        width: 230px;
    }

    font-family: $global-serif-font-family;
    padding: 5px;

    &[type=button],&[type=reset], &[type=submit] {
        padding: 5px 15px;
    
        background-color: $theme-color;
        color: white;
        border-radius: 5px;
        border: 1px solid $theme-color;
        font-family: $global-serif-font-family;
    }
}

label.small {
    width:140px; 
    display:inline-block;
}
@import '_common/normalize';

html {
  font-size: $global-font-size;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: $global-font-family;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: $global-lineheight;
  color: $global-font-color;
  background: $global-background;
  scroll-behavior: smooth;
  border-top: 3px solid $theme-color;

  background-color: #ffffff;
  // credits: https://www.transparenttextures.com
  background-image: url("../img/retina-dust.png");
}

@include max-screen() {
  body {
    border-top: 0;
  }
}

::selection {
  background: $theme-color;
  color: #fff; 
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

a {
  color: $global-font-color;
  text-decoration: none;
}

@each $header, $size in $global-headings {
  #{$header} {
    font-size: $size;
    font-family: $global-serif-font-family;
  }
}

.container {
  margin: 0 auto;
  width: $global-body-width;
}

@include max-screen() {
  .container {
    width: 100%;
    box-shadow: -1px -5px 5px $gray;
  }
}

.content-wrapper {
  padding: $global-container-padding;
}


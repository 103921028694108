code, pre {
  padding: 7px;
  font-size: $code-font-size;
  font-family: $code-font-family;
  background: $code-background;
}

code {
  padding: 3px 5px;
  border-radius: 4px;
  color: $code-color;
}

// ==============================
// Post footer
// ==============================

.post-footer {
  margin-top: $post-footer-margin-top;
  border-top: $post-footer-border-top;
  font-family: $global-serif-font-family;

  .post-tags {
    padding: $post-tags-padding;

    a {
      margin-right: 5px;
      color: $theme-color;

      &::before {
        content: '#';
      }
    }
  }

  .post-nav {
    margin: 1em 0;
    @include clearfix;

    .prev,
    .next {
      font-weight: 600;
      font-size: $post-nav-font-size;
      font-family: $global-serif-font-family;
      transition-property: transform;
      transition-timing-function: ease-out;
      transition-duration: 0.3s;
    }

    .prev {
      float: left;

      &:hover {
        color: $theme-color;
        transform: translateX(-4px); 
      }
    }

    .next {
      float: right;

      &:hover {
        color: $theme-color;
        transform: translateX(4px); 
      }
    }

    .nav-mobile {
      display: none;
    }
  }
}

@include max-screen() {
  .post-footer {
    .post-nav {
      .nav-default {
        display: none;
      }

      .nav-mobile {
        display: inline;
      }
    }
  }
}